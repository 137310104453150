.form {
  overflow: hidden;

  & > div {
    overflow: hidden;
  }

  [role="listbox"] {
    overflow: auto;
    overscroll-behavior: contain;
  }
}
