.info {
  position: absolute;
  top: 0;
  right: 0;

  display: inline;

  width: unset;
}

.manual {
  padding-inline-start: 1.25em;

  > li + li {
    margin-block-start: var(--spacing-primitive-1);
  }

  ul {
    padding-inline-start: 1em;
  }
}

.manual button {
  display: inline;
  width: unset;
  padding: 0.25em 0.5em;
}

.counter {
  font-size: 8rem;
  text-align: center;
}

.selection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
  gap: 2rem;
  justify-content: center;

  button {
    /* touch */
    @media (hover: none) and (pointer: coarse) {
      aspect-ratio: 1;
    }
  }
}

.dance {
  font-size: 36px;
  font-style: italic;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 5px;
}

.playing {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-container0);

  /* min-height: calc(100dvh - var(--offset)); */
}

.stop {
  margin-block-start: auto;
}

.header {
  display: flex;

  h1 {
    margin-bottom: 0;
  }

  button {
    font-size: var(--s0);
  }
}
