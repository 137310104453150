.card {
  display: grid;
  row-gap: 0.8rem;

  small {
    color: var(--muted-color);
  }

  .header {
    display: grid;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
  }
}

.loop {
  display: grid;
  grid-template-columns: auto auto;
  gap: 0.5em;
  justify-content: space-between;

  div {
    display: flex;
    flex-direction: column;
  }

  time {
    font-family: monospace;
    font-size: 0.8em;
  }

  button {
    width: auto;
    margin: 0;
  }
}

.playbutton {
  --percent: -1%;

  border-width: 0;

  &[data-playing] {
    background-image: linear-gradient(
      to right,
      rgb(0 0 0 / 15%) calc(var(--percent) - 1%),
      rgb(0 0 0 / 55%) var(--percent),
      transparent calc(var(--percent) + 1%)
    );
  }
}
