.card {
  background: var(--pico-card-background-color);
  border-radius: var(--pico-border-radius);
  box-shadow: var(--pico-card-box-shadow);
  padding: var(--pico-block-spacing-vertical) var(--pico-block-spacing-horizontal);
}

.section {
  header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
  }
}

.title {
  color: var(--pico-typography-main);
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  margin: 0;
}
