.tracks {
  padding-inline-start: 1.25em;

  li {
    margin-bottom: var(--spacing-container0);
  }

  [aria-selected] {
    font-weight: bold;
  }
}

.counter {
  position: sticky;
  top: 0;

  font-size: 8rem;
  font-variant-numeric: tabular-nums;
  text-align: center;
  white-space: nowrap;
}

.header {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0%, 1fr));
  gap: var(--spacing-container-2);

  p {
    overflow: hidden;
    text-overflow: ellipsis;

    small {
      white-space: nowrap;
    }
  }
}

.play {
  overflow-y: auto;
  overscroll-behavior: contain;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-container-4);
}
