.toolbar {
  font-size: var(--s-2);
}

.share {
  font-size: var(--s0);

  div {
    display: flex;
    gap: var(--spacing-primitive-1);
  }

  input {
    min-width: 15em;
    max-width: 25em;
  }
}
