.bingo {
  nav {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.25em;

    ul {
      display: flex;
      gap: var(--spacing-primitive0);
      align-items: center;

      padding: 0;

      list-style-type: none;
    }
  }

  nav > ul > li {
    padding-block: 0;
  }

  .playfield {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }
}

.bingoText {
  transform: scale(0);

  display: flex;
  gap: 0.1em;

  font-size: var(--s3);
  text-transform: uppercase;

  & > span:nth-child(1) {
    color: purple;
  }

  & > span:nth-child(2) {
    color: teal;
  }

  & > span:nth-child(3) {
    color: yellowgreen;
  }

  & > span:nth-child(4) {
    color: orange;
  }

  & > span:nth-child(5) {
    color: darkred;
  }

  & > span:nth-child(6) {
    color: brown;
  }
}

.bingoTextVisible {
  transform: scale(1);
  animation: 0.75s bounceIn;
}

.counters {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-primitive0);
}

.form {
  flex-direction: row;
  align-items: flex-end;

  & > div {
    flex-grow: 1;
  }
}

.tile {
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  min-height: 100px;
  padding: 0.25rem;

  font-size: 80%;
  hyphens: auto;
  text-align: center;

  border: 1px solid grey;

  &[data-selected] {
    color: var(--indicator-warning-subtle-text);
    background-color: var(--indicator-warning-subtle-background);
    border-color: var(--indicator-warning-plain-border);
  }

  &[data-winner] {
    color: var(--indicator-success-subtle-text);
    background-color: var(--indicator-success-subtle-background);
    border-color: var(--indicator-success-subtle-border);
    border-width: 2px;
  }
}

@keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    transform: scale3d(0.3, 0.3, 0.3);
    opacity: 0;
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    transform: scale3d(1.03, 1.03, 1.03);
    opacity: 1;
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  100% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}
