.main {
  padding-top: 4.5rem;
  padding-bottom: 6rem;

  main {
    position: relative;
  }

  :is(&[data-game], &[data-assistant]) main {
    @media (width <= 992px) {
      display: none;
    }
  }
}

.label {
  padding-block: var(--spacing-primitive-4);
  padding-inline: var(--spacing-primitive-3);

  font-size: 80%;
  font-weight: 400;
  color: var(--typography-muted);
}
