:root {
  --artistry: #FEB019;
  --comm: #00E396;
  --tricks: #008FFB;
  --filler: #775DD0;
  --void: #A5978B;
  --dismounts: #FF4560;
}

.timeline {
  svg {
    background: none !important;
  }

  :global(.vis-timeline > .vis-background:first-child) {
    background-color: var(--surface-container1);
  }

  :global(.vis-time-axis.vis-background) {
    background-color: var(--surface-container);
  }

  :global(.vis-label) {
    border-color: var(--control-border-color);
    color: var(--typography-subtle);
  }

  :global(.vis-text) {
    border-right: 0.5px solid var(--control-border-color);
    color: var(--typography-muted);
    background-color: var(--surface-container1);
  }

  :global(.vis-panel),
  :global(.vis-timeline),
  :global(.vis-group) {
    border-color: var(--control-border-color);
  }

  :global(.vis-time-axis .vis-grid.vis-vertical) {
    border-left: 0.5px solid hsl(from var(--control-border-color) h s calc(l - 5));
  }

  :global(.vis-group:first-child .vis-item) {
    margin-top: -15px;
  }

  :global(.marker .vis-item) {
    border-color: var(--shape-stroke-color);
    color: var(--typography-main);
    background-color: var(--surface-popover);
  }

  :global(.artistry .vis-item) {
    border-color: var(--artistry);
    background-color: var(--artistry);
  }

  :global(.communication .vis-item) {
    border-color: var(--comm);
    background-color: var(--comm);
  }

  :global(.tricks .vis-item) {
    border-color: var(--tricks);
    background-color: var(--tricks);
  }

  :global(.filler .vis-item) {
    border-color: var(--filler);
    background-color: var(--filler);
  }

  :global(.void .vis-item) {
    border-color: var(--void);
    background-color: var(--void);
  }

  :global(.dismounts .vis-item) {
    border-color: var(--dismounts);
    background-color: var(--dismounts);
  }

  :global(.vis-item.vis-selected) {
    border-color: var(--control-border-color);
    background-color: var(--control-focus-selected-background);
  }
}

.summary {
  border-collapse: collapse;

  thead {
    background: var(--surface-container);
  }

  thead, tbody {
    border: 1px solid var(--shape-stroke-color);
  }

  th {
    text-align: left;
  }

  th, td {
    padding: 0.5rem 0.75rem;

    &:first-child {
      border-inline-end: 1px solid var(--shape-stroke-color);
    }

    &:last-child {
      border-inline-start: 1px solid var(--shape-stroke-color);
    }
  }

  td:last-child,
  td:nth-child(3) {
    text-align: right;
  }

  [data-vis] {
    width: 40%;

    span {
      display: inline-block;
      width: var(--ratio, 0);
      height: 1em;

      &[data-group="artistry"] {
        background-color: var(--artistry);
      }

      &[data-group="communication"] {
        background-color: var(--comm);
      }

      &[data-group="tricks"] {
        background-color: var(--tricks);
      }

      &[data-group="filler"] {
        background-color: var(--filler);
      }

      &[data-group="dismounts"] {
        background-color: var(--dismounts);
      }

      &[data-group="void"] {
        background-color: var(--void);
      }
    }
  }
}

.shortcuts {
  border-collapse: collapse;

  thead {
    background: var(--surface-container);
  }

  thead, tbody {
    border: 1px solid var(--shape-stroke-color);
  }

  th, td {
    text-align: left;
  }

  th, td {
    padding: 0.5rem 0.75rem;

    /* &:first-child {
      border-inline-end: 1px solid var(--shape-stroke-color);
    }

    &:last-child {
      border-inline-start: 1px solid var(--shape-stroke-color);
    } */

    &:is(:first-child, :nth-child(2)) {
      border-inline-start: 1px solid var(--shape-stroke-color);
    }

    &:is(:last-child, :nth-child(3)) {
      border-inline-start: 1px solid var(--shape-stroke-color);
    }
  }


}
