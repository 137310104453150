.layout {
  display: flex;
  flex-wrap: wrap;
  gap: var(--s1);

  ul {
    padding-left: calc(var(--spacing-primitive-3) * 2);
  }

  > :first-child {
    flex-grow: 1;
  }

  > :last-child {
    flex-basis: 0;
    flex-grow: 999;
    min-inline-size: 75%;

    &:not(.index) > * {
      display: flex;
    }
  }
}

.index {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-primitive0);
}
