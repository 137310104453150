.page {
  header > p > p:not(:has(+ *)) {
    padding: 0;
  }
}

.layout {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  iframe {
    margin-bottom: var(--block-spacing-vertical);
  }

  .main {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-primitive0);
  }

  aside {
    & > section {
      padding: 1rem;
      background: var(--card-background-color);
      border-radius: var(--border-radius);
      box-shadow: var(--card-box-shadow);
    }

    li a {
      padding-left: 1.7em;
      text-indent: -1.2em;
    }
  }
}

.layout > :last-child {
  flex-basis: 30%;
  flex-grow: 1;
}

.layout > :first-child {
  flex-basis: 0;
  flex-grow: 999;
  min-inline-size: 65%;
}

.player {
  margin-bottom: 2em; /* boooh */
}
