.comparison {
  header {
    border-bottom: var(--border-width) solid var(--table-border-color);
  }

  ul {
    font-size: 80%;
    padding-left: 1em;
  }
}

.measures {
  --min: 25ch;
  --gap: 1rem;

  display: grid;
  grid-gap: var(--gap);

  /* min() with 100% prevents overflow in extra narrow spaces */
  grid-template-columns: repeat(auto-fit, minmax(min(100%, var(--min)), 1fr));
}
