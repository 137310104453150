.units {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

  article {
    margin: 0;

    p {
      margin: 0;
    }
  }

  margin: var(--block-spacing-vertical) 0;
}

/* .lessons {
  counter-reset: lesson;
} */

.lesson {
  counter-increment: lesson;
  margin-top: calc(var(--pico-typography-spacing-vertical) * 1.5);
}

.lesson::before {
  content: counter(lesson) ". ";
}

.goals, .contents {
  padding-inline-start: 1em;
}

.examples {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-bottom: var(--pico-typography-spacing-vertical);
}
