.card {
  display: flex;
  flex-direction: column;

  [part="header"] {
    display: grid;
    gap: var(--spacing-primitive-4);

    div {
      display: flex;
      flex-wrap: wrap;
      gap: var(--spacing-primitive-4);
    }
  }

  [part="body"] {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 0.75em;
    justify-content: space-between;

    ul {
      display: flex;
      flex-flow: row wrap;
      gap: 0.5em;
      justify-content: flex-end;

      margin: 0;
      padding: 0;

      font-size: 80%;
    }

    li {
      text-wrap: nowrap;
      list-style-type: none;
    }
  }
}
