.playbutton {
  --background-base: #1ed760;
  --background-highlight: #1fdf64;
  --background-press: #169c46;

  position: relative;

  display: inline-flex;
  gap: 0.25em;
  align-items: center;
  justify-content: center;

  padding: var(--spacing-primitive0);
  padding-inline: var(--spacing-primitive2);

  color: #000;

  background-color: var(--background-base);
  border-color: var(--background-base);
  border-radius: 5em;

  svg {
    aspect-ratio: 1;
    width: 1em;
    fill: currentcolor;
    stroke: currentcolor;
  }

  &:hover {
    background-color: var(--background-highlight);
    border-color: var(--background-highlight);
  }

  &:active {
    background-color: var(--background-press);
    border-color: var(--background-press);
  }

  &[disabled] {
    pointer-events: none;
    cursor: not-allowed;
    opacity: .5;
  }
}
